import gql from 'graphql-tag';

export const UPDATE_PAYU_PAYMENT_SUBSCRIPTION = gql`
  subscription UpdatePayUPayment{
    updatePayUPayment{
        customerID
        status
    }
  }
`;

export const EXTEND_MEMBERSHIP = gql`
    mutation ExtendMembership{
        extendMembership     
    }
`;

export const CREATE_PAYMENT = gql`
    mutation CreatePayment(
        $customerID:ID!,
        $token:String!,
        $membershipDuration:ID!,
        $name: String,
        $surname: String,
        $email: String,
        $city: String,
        $street: String,
        $zip: String
        $autoPayment:Boolean
        $domain:String
        $tel:String
        $serviceID: ID
        $customerServiceID:ID
        $onlyActiveMem:Boolean
    ){
        createPayment(
            customerID:$customerID,
            token:$token,
            membershipDuration:$membershipDuration,
            name:$name,
            surname:$surname,
            email:$email,
            city:$city,
            street:$street,
            zip:$zip,
            autoPayment:$autoPayment
            domain:$domain
            tel:$tel
            serviceID:$serviceID
            customerServiceID:$customerServiceID
            onlyActiveMem:$onlyActiveMem
        ){
            customerPaymentID
            status
            clientSecret
        }     
    }
`;

