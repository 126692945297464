import gql from 'graphql-tag';

export const GET_PAYMENT_STATUS = gql`
mutation GetPaymentStatus($paymentID:ID!){
    getPaymentStatus(paymentID:$paymentID){
        message
        error
        showButton
    }     
}
`;

export const EXTEND_MEMBERSHIP = gql`
mutation ExtendMembership{
    extendMembership     
}
`;