import React,{ Component } from 'react';
import logo from '../../../Public/Images/logo.png';
import { withApollo } from 'react-apollo';
import {GET_PAYMENT_STATUS} from '../Queries/gopay';
import Loading from '../../../GlobalComponents/Loading';
import translate from '../../Translations/index';

const INITIAL_STATE = {	 
	error: null,
  message:'',
  showButton:false,
  loading:false
};

class Status extends Component {
		
  constructor(props){
	    
	  super(props);
	  this.goBack   = this.goBack.bind(this);
    this.state    = { ...INITIAL_STATE };
    
    this.checkPaymentStatus = this.checkPaymentStatus.bind(this);
	  
  }	

  componentDidMount(){

    this.checkPaymentStatus();

  }
      
  goBack(){ 
	  this.props.history.push("/");
  }

  async checkPaymentStatus(){

      this.setState({loading:true});

      var paymentID = 0;
      if(this.props.location.search != ""){

        paymentID = this.props.location.search.split("=");
        paymentID = paymentID[1];

      }

      var data = await this.props.client.mutate({
          mutation:GET_PAYMENT_STATUS,
          variables:{paymentID}
      });

      if(data.data.getPaymentStatus.error != ""){
        this.setState({loading:false,error:data.data.getPaymentStatus.error,showButton:data.data.getPaymentStatus.showButton});
      }else if(data.data.getPaymentStatus.message != ""){
          this.setState({loading:false,error:"",message:data.data.getPaymentStatus.message,showButton:data.data.getPaymentStatus.showButton});
      }

  }
  
  render() {

	const {error,message,loading,showButton} = this.state;  
	  
    return (
        <div id="login">
        
	        <div className="logo">
		        <div><img alt="rea shop" src={logo} /></div>
            <div>{translate.t('AstralProjection')}</div>
		    </div>
        	<div className="panel">
              {loading ?
                <div className="inner-panel"><Loading /></div>
                :
                <div className="inner-panel">
                  
                  { error && <div className="alert alert-danger form-group">{error}</div> }
                  { message && <div className="alert alert-success form-group">{message}</div> }
                  
                  {showButton &&
                    <div>
                      <button onClick={() => this.checkPaymentStatus()} className="btn btn-primary btn-blue btn-block">{translate.t('CheckPaymentStatus')}</button>
                    </div>
                  }
                  {error == "" &&
                    <div>
                      <button onClick={this.goBack} className="btn btn-primary btn-blue btn-block">{translate.t('ContinueToWatchVideos')}</button>
                    </div>
                  }

                </div>
              }
        	</div>
        
        </div>
    );
  }
}



export default withApollo(Status);
