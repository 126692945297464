/*
    Úvodní layout administrace
*/
import React,{ Component } from 'react';
import Header from './Header';
import Notification from '../GlobalComponents/Notification';
import {Switch, Route } from 'react-router-dom';
import withAdminAuthentication from '../Modules/Auth/Components/WithAuthentication';
import ChangePassword from '../Modules/Auth/Components/ChangePassword';
import Video from '../Modules/Video/Components/Video';;

class Layout extends Component {
	
  render() {
	  	
    return (
	   
    	<div className="wholeContent">			       
 	      
 	        <div id="layout">	
				<Header />  
				<Switch>
        
					<Route exact path='/video' component={Video}></Route>
					<Route exact path='/settings/change-password' component={ChangePassword}></Route>
					<Route path='/:category/:video' component={Video}></Route>
					<Route path='/v/:video' component={Video}></Route>
					

				</Switch>
				<Notification />
			</div>
		     	       
		</div>
   
    );
  }
}

export default withAdminAuthentication(Layout);
