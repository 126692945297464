import React,{ Component } from 'react';
import { withApollo } from 'react-apollo';

const INITIAL_STATE = {	 
	error: null,
	message:''
};


class NotifyPayment extends Component {
		
  constructor(props){
	    
	  super(props);
	  this.state    = { ...INITIAL_STATE };
	  
  }	

  componentDidMount(){


    
  }

  
  
  render() {
	  
    return (
        <div></div>
    );
  }
}



export default withApollo(NotifyPayment);
