import React,{ Component } from 'react';
import { Mutation, ApolloConsumer,withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import {GET_CUSTOMER_FIRST_VIDEO} from '../../Video/Queries/video';
import translate from '../../Translations/index';

const LOGIN_USER = gql`
  mutation login($email: String!,$password: String!) {
    login(email: $email,password: $password){
        loginToken
        customer{
            name
            surname
            customerID
            membershipTo
            membershipDuration
            membership{
                unlockAllVideos
            }
        }
    }
  }
`;

class Login extends Component{

    constructor(props){
        super(props);
    }
  
    render(){
        return (
            <ApolloConsumer>
                {client => (
                    <Mutation
                    mutation={LOGIN_USER}
                    onCompleted={async (data) => {

                        if(!data.login)this.props.error(translate.t('WronCredentialInserted'));
                        else{
                            localStorage.setItem('token', data.login.loginToken);
                            localStorage.setItem('loggedUser', data.login.customer.name+" "+data.login.customer.surname);
                            localStorage.setItem('membershipTo', data.login.customer.membershipTo);
                            client.writeData({ data: { isLoggedIn: true } });

                            var dat = await this.props.client.query({
                                query:GET_CUSTOMER_FIRST_VIDEO,
                                variables:{
                                    lang:translate.locale
                                }
                            });

                            var url = localStorage.getItem("urlToShow");
                            localStorage.setItem("urlToShow", "");
                            
                            if(url && url != ""){
                                this.props.history.push(url);
                            }else{
                                if(data.login.customer.membership.unlockAllVideos)
                                    this.props.history.push('/v/' + dat.data.firstCustomerVideo.hash);
                                else
                                    this.props.history.push('/' + dat.data.firstCustomerVideo.category.niceName + '/' + dat.data.firstCustomerVideo.hash);
                            }
                        }
                    }}
                    >
                    {(login, { loading, error }) => {
                        // this loading state will probably never show, but it's helpful to
                        // have for testing
                        if (loading) return "";
                        if (error) return this.props.error(error);

                        return <button type="button" onClick={() => this.props.login(login)} className="btn btn-primary btn-blue btn-block">{translate.t('LogIn')}</button>;
                    }}
                    </Mutation>
                )}
            </ApolloConsumer>
        );
    }
}

export default withApollo(Login);