import React,{ useState } from 'react';
import Modal from 'react-modal';
import translate from '../Modules/Translations/index';
import { withApollo} from 'react-apollo';
import Loading from "./Loading";
import StripeLib from '../Modules/PayU/Library/stripe';
import close from '../Public/Images/Icons/close.webp';

import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#321F06",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "18px",
      "::placeholder": {
        color: "#7d7d7d",
      },
      backgroundColor:"#eee"
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
  hidePostalCode:true
};

Modal.setAppElement('#root');


const ModalExtendMembership = (props) => {

  const stripeLib = new StripeLib(props);

  const stripe = useStripe();
  const elements = useElements();

  const [error,setError] = useState(false);
  const [loading,setLoading] = useState(false);
  const [successPayment, setSuccesPayment] = useState(false);
  const [paymentError, setPaymentError] = useState(null);

  const repeatPament = () => {
    setPaymentError("");
}
	  	
  return (
    <Modal
        className="Modal__Bootstrap modal-dialog"
        closeTimeoutMS={150}
        isOpen={true}
        onRequestClose={() => props.callback(false)}
    >
        <div className="modal-content">
          <div className="modal-header">
            {translate.t("ExtendMem")}
            <img src={close} className="close" onClick = {() => props.callback(false)} />
            
          </div>
          <div className="modal-body">

          {!loading ?
                                
              <>

                  
                  {paymentError ?

                  <>

                      <div className="alert alert-danger text-center">{paymentError}</div>
                      <button onClick={() => repeatPament()} className="btn btn-primary btn-block bigger">{translate.t("RepeatPayment")}</button>

                  </>


                  :

                      (successPayment ?

                          <div className="alert alert-success text-center no-bottom-margin">{translate.t("ThanksPaymentIsOkMembershipActive")}</div>

                      :

                          <>
                              <p className="no-top-margin">{translate.t("PaymentBy")} <a target="_blank" href="https://www.stripe.com">Stripe</a>. {translate.t("AllCardData")}  <strong>{translate.t("NotBeKept")}</strong>{translate.t("SavedInPayu")}</p>
                              <p>{translate.t("PleaseFillCardData")}</p>
                              
                              
                                  
                              <CardElement options={CARD_ELEMENT_OPTIONS} />
                              <div className="text-center">
                                  <button style={{borderRadius:"0 0 5px 5px"}} className="btn btn-primary btn-large btn-block" onClick={(e) => stripeLib.makeOrder(e,setPaymentError,stripe,elements,CardElement,props.customerContext,setSuccesPayment,setLoading,setError,props.client,props.customerContext.regCode,props.customerContext.domain)} >{translate.t("Pay")}</button>
                                  
                              </div>

                              {error != "" &&
                                  <div className="alert alert-danger mt-3 text-center">{error}</div>
                              }  

                              
                          </>

                      )

                  }

              </>

          :
              <Loading />
          }    
          </div>
              
        </div>
    </Modal>
  );
  
  
}


export default withApollo(ModalExtendMembership);
