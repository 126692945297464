/*
    routování aplikace
*/
import React, { Component } from 'react';
import {Switch, Route } from 'react-router-dom';
import LoginForm from '../Modules/Auth/Components/LoginForm';
import ResetPassword from '../Modules/Auth/Components/ResetPassword';
import NewPassword from '../Modules/Auth/Components/NewPassword';
import GoPayStatus from '../Modules/GoPay/Components/Status';
import GoPayNotifyPayment from '../Modules/GoPay/Components/NotifyPayment';
import Layout from '../Layout/Layout.js';
import NoMatch from './NoMatch';


class Routes extends Component {
	
  render() {
	  	  
    return (
        <Switch>
        
          <Route exact path='/' component={LoginForm}></Route>
          <Route exact path='/gopay/status' component={GoPayStatus}></Route>
          <Route exact path='/gopay/notify-payment' component={GoPayNotifyPayment}></Route>
          <Route exact path='/reset-password' component={ResetPassword}></Route>
          <Route exact path='/new-password/:code' component={NewPassword}></Route>
          <Route path='/:l' component={Layout}></Route>
          
	        <Route component={NoMatch} />

	      </Switch>
    );
  }
  
}
export default Routes;