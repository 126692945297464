/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci autentikací
 */
import Notification from '../../../Library/notification';
import translate from '../../Translations/index';

class Auth {

    constructor(parent){
        this.parent = parent;
    }

    changePassword(changePassword){ 

        const data = this.parent.state.password;
        let notify = new Notification();  
        
        if(data.oldPassword !== ''){
			if(data.newPassword !== '' && data.checkNewPassword !== '' && data.newPassword === data.checkNewPassword){
				
				if(data.newPassword !== data.oldPassword){
					
					changePassword({
                        variables:{
                            password:data.newPassword,
                            oldPassword:data.oldPassword,
                            lang:translate.locale
                        }
                    });
					
				}else{
					notify.setNotification(null,translate.t('OldAndNewPwdsMustNotBeSame'),false,true,this.parent.props.client);
				}
				
			}else{
                notify.setNotification(null,translate.t('NewPwdsNotSame'),false,true,this.parent.props.client);
			}
		}else{
            notify.setNotification(null,translate.t('NotFilledOldPwd'),false,true,this.parent.props.client);
		}
    }

    saveNewPassword(saveNewPassword,passCode,lang){

        const {password,checkPassword} = this.parent.state;
        let notify = new Notification();  
        
        if(passCode && passCode != ""){
            if(password !== '' && checkPassword !== ''){
                if(password === checkPassword){
                    
                    saveNewPassword({
                        variables:{
                            passCode:passCode,
                            password:password,
                            lang:lang
                        }
                    });

                }else{
                    this.parent.setState({error:translate.t('NewPwdsNotSame')});
                    setTimeout(() => this.parent.setState({'error':''}),3000);
                }
            }else{
                this.parent.setState({error:translate.t('NotFilledPwd')});
                setTimeout(() => this.parent.setState({'error':''}),3000);
            }
        }else{
            this.parent.setState({error:translate.t('WrongUser')});
            setTimeout(() => this.parent.setState({'error':''}),3000);
        }

    }

    formHandle(e){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        this.parent.setState({...this.parent.state,password:{...this.parent.state.password,[n]: v}});
              
    }

}

export default Auth