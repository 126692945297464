import React,{Component} from 'react';
import { Query, withApollo } from 'react-apollo';
import {GET_CUSTOMER_VIDEO,UPDATE_VIEWED_VIDEO} from '../Queries/video';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import {GET_HEADER_DATA} from '../../../GlobalQueries/globalQueries';
import translate from '../../Translations/index';

class Video extends Component{

    constructor(props){
        super(props);
    }

    render(){

        const {match,client} = this.props;

        return(
            <div id="video" className="whole-container">	
                {match.params && match.params.video ?

                    <Query
                        query={GET_CUSTOMER_VIDEO}
                        variables = {{hash:match.params.video,lang:translate.locale}}
                        onCompleted = {async (data) => {

                            if(data.customerVideo && data.customerVideo.viewed == false){
                                
                                await client.mutate({ 
                                    mutation: UPDATE_VIEWED_VIDEO,
                                    variables:{videoID:data.customerVideo.videoID}, 
                                    update:(cache, response)=>{

                                        const { allCategories, allCustomerVideos, customerContext } = cache.readQuery({ query: GET_HEADER_DATA, variables:{lang:translate.locale}});

                                        var arr = [...allCategories];
                                        var arr2;

                                        for(let i in arr){
                                            if(arr[i].videos && arr[i].videos.length > 0){

                                                var checked = false;
                                                for(let j in arr[i].videos){

                                                    arr2 = [...arr[i].videos]
                                                    if(arr[i].videos[j].videoID != data.customerVideo.videoID && arr[i].videos[j].viewed == false && arr[i].videos[j].activeForUser == true){
                                                        checked = true;
                                                    }

                                                    if(arr[i].videos[j].videoID == data.customerVideo.videoID){

                                                        arr2[j] = {...arr2[j],viewed:true}
                                                        arr[i] = {...arr[i],videos:arr2}

                                                    }else{
                                                        arr2[j] = {...arr2[j]}
                                                        arr[i] = {...arr[i],videos:arr2}
                                                    }
                                                }

                                                if(checked){
                                                    arr[i] = {...arr[i]}
                                                }else{
                                                    arr[i] = {...arr[i],hasNewVideo:false}
                                                }

                                            }else{
                                                arr[i] = {...arr[i]}
                                            }
                                        }

                                        var arr3 = [...allCustomerVideos];
                                        for(let j in arr3){

                                            if(arr3[j].videoID == data.customerVideo.videoID)
                                            {
                                                arr3[j] = {...arr3[j],viewed:true}
                                            }
                                        }

                                        cache.writeQuery({ 
                                            query:GET_HEADER_DATA,
                                            variables:{
                                                lang:translate.locale
                                            },
                                            data:{
                                                allCategories: arr,
                                                customerContext:customerContext,
                                                allCustomerVideos:arr3

                                            } 
                                        });
                                        
                                        
                                    }
                                });
                            }

                        }}
                    >
                    {
                        ({data,loading,error }) => {

                            if(loading) return (<Loading />);
                            if(error) return (
                                <div className="no-video">
                                    <div>
                                        <Error text={error} />
                                    </div>
                                </div>
                            );

                            if(!data.customerVideo) return (
                                <div className="no-video">
                                    <div>
                                        <Error text={translate.t("VideoNotActiveYet")} />
                                    </div>
                                </div>
                            );
                            
                            return(
                                
                                <iframe width="100%" height="100%" src={"https://player.vimeo.com/video/" + data.customerVideo.url} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                
                            )
                        }
                    }
                    </Query>
                
                :
    		    <div className="no-video">
                    <div>
                        {translate.t("ChooseYourVideoFromMenu")}
                    </div>
                </div>		
                }
            </div>
        );

    }
}

export default withApollo(Video);