import React,{ Component } from 'react';
import Modal from 'react-modal';
import {SEND_QUESTION} from "../GlobalQueries/globalQueries";
import translate from '../Modules/Translations/index';
import close from '../Public/Images/Icons/close.webp';

Modal.setAppElement('#root');

const INITIAL_STATE = {
    modalError:"",
    modalSuccess:"",
    text:""
}

class ModalSupport extends Component {

    constructor(props){
        super(props);

        this.state = INITIAL_STATE;

        this.sendMessage = this.sendMessage.bind(this);
    }

    sendMessage(){ 

      if(this.state.text != ""){

        this.props.client.mutate({
            mutation:SEND_QUESTION,
            variables:{
              text:this.state.text
            },
            update:() => {
              this.setState({modalSuccess:translate.t("QuestionSent")});
              setTimeout(() => this.setState({'modalSuccess':''}),4000);
            }
        });

      }else{
        this.setState({modalError:translate.t("NotFilledQUestion")});
        setTimeout(() => this.setState({'modalError':''}),4000);
      }
    }  
	  	
  render() {
	
    const {modalError,text,modalSuccess} = this.state;

    return (
    	<Modal
	        className="Modal__Bootstrap modal-dialog"
	        closeTimeoutMS={150}
	        isOpen={true}
	        onRequestClose={() => this.props.callback(false)}
	    >
	        <div className="modal-content">
	          <div className="modal-header">
	            {translate.t("Support")}
	            <img src={close} className="close" onClick = {() => this.props.callback(false)} />
	          </div>
	          <div className="modal-body">
	          
                {modalError &&
                    <div className="alert alert-danger">{modalError}</div>
                }
                {modalSuccess &&
                    <div className="alert alert-success">{modalSuccess}</div>
                }
                <p>{translate.t("SupportFormText1")}</p><p> {translate.t("SupportFormText2")}</p>
                <p>
                    <label><strong>{translate.t("Question")}</strong></label> 
                    <textarea className="form-control" value={text} onChange={(e) => this.setState({text:e.target.value})}></textarea>
                </p>       
	          </div>
			  
            <div className="modal-footer"> 
                <button type="button" className="btn btn-primary flex-fill" onClick={() => this.sendMessage()}>{translate.t("SendQuestion")}</button>
                <button type="button" className="btn btn-danger" onClick={() => this.props.callback(false)}>{translate.t("Close")}</button>
            </div>
			  
	        </div>
	    </Modal>
    );
  }
  
}


export default ModalSupport;
