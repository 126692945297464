/*
    Hlavní horní lišta, ktaré má v sobě i levé mennu
*/
import React,{ Component,useState } from 'react';
import { NavLink } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import plus from '../Public/Images/Icons/plus.webp';
import minus from '../Public/Images/Icons/minus.webp';
import play from '../Public/Images/Icons/play.webp';
import logo from '../Public/Images/logo.svg';
import menu from '../Public/Images/Icons/menu.webp';
import door from '../Public/Images/Icons/logout.webp';
import star from '../Public/Images/Icons/star.webp';
import member from '../Public/Images/Icons/membership.webp';
import help from '../Public/Images/Icons/help.webp';
import user from '../Public/Images/Icons/user_small.webp';
import { ApolloConsumer,withApollo,useSubscription,useQuery} from 'react-apollo';
import {GET_HEADER_DATA} from '../GlobalQueries/globalQueries';
import Loading from '../GlobalComponents/Loading';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ModalExtendMembership from '../GlobalComponents/ModalExtendMembership';
import ModalSupport from '../GlobalComponents/ModalSupport';
import translate from '../Modules/Translations/index';
import moment from 'moment';
import {UPDATE_PAYU_PAYMENT_SUBSCRIPTION} from '../Modules/PayU/Queries/payu';

const Header = (props) => {

	const [cl,setCl] = useState("");
	const [showMem,setShowMembership] = useState(false);
	const [showExtendedModal,setShowExtendedModal] = useState(false);
	const [showSupp,setShowSupport] = useState(false);
	const [showSubmenu,setShowSubmenu] = useState([
		{menuID:1,show:false},
		{menuID:2,show:false},
		{menuID:3,show:false},
		{menuID:4,show:false},
		{menuID:5,show:false},
		{menuID:6,show:false},
		{menuID:7,show:false},
		{menuID:8,show:false},
		{menuID:9,show:false},
		{menuID:10,show:false}
	]);

	const {data,loading,error,refetch:headerRefetch} = useQuery(GET_HEADER_DATA,{
		fetchPolicy: 'network-only',
		variables:{
			lang:translate.locale
		},
	});

	const { data: subscibeData, loading:subscibeLoading } = useSubscription(UPDATE_PAYU_PAYMENT_SUBSCRIPTION,{
        onSubscriptionData(d){

            if(d.subscriptionData.data.updatePayUPayment && data && data.customerContext && d.subscriptionData.data.updatePayUPayment.customerID == data.customerContext.customerID){
                headerRefetch();
            }
        }
	});
	
		  
	const showSideBar = (e) => {
		e.preventDefault();
		if(cl != ""){
			setCl("");
		}else{
			setCl("open");
		}
	}
	const hideSideBar = (e) => {
		setCl("");
	}
	
	const logout = (e,client) => {	  

		e.preventDefault();
		client.resetStore();

		localStorage.removeItem("token");
		localStorage.removeItem("loggedUser");
		localStorage.removeItem("membershipTo");

		window.location = "/";

	}
	  
	const toogleSubmenu = (e,menu,prevent) => {
		  		  
		if(prevent)e.preventDefault();
			  
		var arr = showSubmenu.map(function(item,index){
			if(index !== menu)return {...item,show:false};
			else return {...item,show:!item.show};
		});
		  
		if(prevent)setShowSubmenu(arr);
		else{
			setShowSubmenu(arr);
			setCl("");
		}
	}
	  
	const handleClickOutside = evt => {
		hideSideBar();
	};  

	const showMembership = () => {
		setShowMembership(!showMem);
	}

	const showExtendMembership = () => {
		setShowExtendedModal(true);		
	}
	const extendMembership = (act) => {
		setShowExtendedModal(false);		
	}

	const showSupport = () => {
		setShowSupport(true);	
	}
	const closeSupport = () => {
		setShowSupport(false);	
	}  

	const goToVS = () => {
			
	}  
	 						
	return (
		<div id="header" className="mb-1">
			<ul className="d-flex m-0 p-0 main">
				{loading ?
					null
				:
					<>

						<li className="menu-icon"><button tabIndex="0" onClick={showSideBar} ><img src={menu} /></button></li>
						<li className="logo" onClick={() => hideSideBar()}><img src={logo} /> </li>
						{data && data.customerContext.membershipTo && data.customerContext.membershipTo != "0000-00-00 00:00:00" ?
							
							((data.customerContext.completedCustomerPaymentsCount == 11 && data.customerContext.membershipDuration == 1) || (data.customerContext.completedCustomerPaymentsCount == 1 && (data.customerContext.membershipDuration == 2 || data.customerContext.membershipDuration == 3)) ?
							
								<>
									<li className="renew-membership ml-auto">
										<a 
											target="_blank" 
											href="https://www.astralniakademie.cz/kurz-ac" 
											className="btn btn-primary btn-thiner"
										>
											Týden zdarma v Astrální akademii - buď mezi svými.
										</a>
									</li>
								</>
							:
								<>
									<li className="fs-14 membership ml-auto" onClick={() => hideSideBar()}><strong>{translate.t('MembershipValidTo')}:  <span>{moment(data.customerContext.membershipTo).format("DD.MM.YYYY")}</span></strong></li>
									{moment(data.customerContext.membershipTo).hours(23).minutes(59).seconds(59) < moment() ?
										<li className="renew-membership"><button onClick={() => showExtendMembership()} className="btn btn-primary btn-thiner">Prodloužit členství</button></li> 
									:null}
								</>
							)

						:null}

						{data && data.customerContext.membershipTo && data.customerContext.membershipTo != "0000-00-00 00:00:00" ? 
							<li className="mobile-membership ml-auto">
								<button onClick={() => showMembership()}><img src={member} /></button>
								{showMem &&
									((data.customerContext.completedCustomerPaymentsCount == 11 && data.customerContext.membershipDuration == 1) || (data.customerContext.completedCustomerPaymentsCount == 1 && (data.customerContext.membershipDuration == 2 || data.customerContext.membershipDuration == 3)) ?
									<ul>
										<li style={{width:"100%"}}>
											<a 
												className = "btn btn-primary text-center  btn-thiner" 
												target="_blank" 
												href="https://www.astralniakademie.cz/kurz-ac"
											>Týden zdarma v Astrální akademii - buď mezi svými.</a>
										</li>
									</ul>
									:
									<ul>
										<li className="fs-14 membership">
											<strong>
												{translate.t('MembershipValidTo') + ": "} 
												<span>{moment(data.customerContext.membershipTo).format("DD.MM.YYYY")}</span>
											</strong>
										</li>
										{moment(data.customerContext.membershipTo).hours(23).minutes(59).seconds(59) < moment() ?
											<li className="ml-auto">
												<button 
													onClick={() => showExtendMembership()} 
													className="btn btn-primary  btn-thiner"
												>Prodloužit členství</button>
											</li>
										:null}
									</ul>
									)
								}
							</li>
						:null}

						<li className="">
							<button onClick={() => showSupport()}><img src={help} /></button>
						</li>
						
						
						<li className="user">
							<NavLink onClick={() => hideSideBar()} to="/settings/change-password">
								<div className="header-avatar"><img src={user} /></div>
							</NavLink></li>
						<li className="">
							<ApolloConsumer>
								{client => (
									<button onClick={(e) => logout(e,client)}><img src={door} /></button>
								)}
									
							</ApolloConsumer>
						</li>
					</>
				}
			</ul>
			<div className={"sideBar " + cl}>

				<img alt="Astrální akademie" className="logo" src={logo} />
				
				{loading ?
					<Loading />
				:
					<>
						<PerfectScrollbar>
							{data.customerContext.membership.unlockAllVideos == 0 ?
								<ul>
									{data.allCategories && data.allCategories.map((item,index) => (
										<li key={index}>
											
											<NavLink 
												onClick={(e) => toogleSubmenu(e,index,true)} 
												activeClassName="active" 
												to={"/" + item.niceName}
											> {
												showSubmenu[index] && showSubmenu[index].show ? 
													<img src={minus} /> 
												: 
													<img src={plus} />
												} {item.name} 

												{item.videos && item.videos.length > 0 && item.hasNewVideo == true ? 
													<div className="badge badge-danger">
														Nové
													</div> 
													:
													""
												}
											</NavLink>
											
											{item.videos && item.videos.length > 0 ? 
												<ul className={(showSubmenu[index] && showSubmenu[index].show) ? '' : 'hidden' }>
													{item.videos.map((video,vInd) => (
														<li key={vInd} className={(video.activeForUser ? "activated" : "deactivated")}>
															{(video.activeForUser ? 
																<NavLink 
																	onClick={() => hideSideBar()} 
																	exact 
																	activeClassName="active" 
																	to={"/" + item.niceName + "/" + video.hash}
																> 
																	{(video.viewed == false ? 
																		<img src={star} className="star" /> 
																	: "")} {video.name}

																	<img 
																		className="play" 
																		src= {play}
																	/>
																</NavLink> 
															: 
																<span>{video.name}</span>
															)}
														</li>
													))}
												</ul>

												:
												<ul className={(showSubmenu[index] && showSubmenu[index].show) ? '' : 'hidden' }>
													<li style={{backgroundColor:'#E89848',margin:0,textAlign:'center'}}>{translate.t('VideoWillBeAddedLater')}</li>
												</ul>
											}
										</li>
									))}
									
								</ul>
							:
								
								<ul className="all">
									{data.allCustomerVideos.map((video,vInd) => (
										<li key={vInd} className={(video.activeForUser ? "activated" : "deactivated")}>
											{(video.activeForUser ? 
												<NavLink 
													onClick={() => hideSideBar()} 
													exact 
													activeClassName="active" 
													to={"/v/" + video.hash}
												> 
													{(video.viewed == false ? 
														<img src={star} className="star" /> 
													: "")} {video.name}

													<img 
														className="play" 
														src= {play}
													/>
												</NavLink> 
											: 
												<span>{video.name}</span>
											)}
										</li>
									))}
								</ul>

							}
						</PerfectScrollbar>

						<div className="dot tl"></div>
						<div className="dot tr"></div>
					</>
				}
			</div>
			{showExtendedModal &&
				<ModalExtendMembership customerContext={data.customerContext} client={props.client} callback={extendMembership} />
			}
			{cl == "open" ?
				<div className="side-bar-overlay" onClick={() => setCl("")}></div>
				:
				null
			}
			{showSupp && 
				<ModalSupport client={props.client} callback={closeSupport} />
			}
		</div>
				
	);
	  
  
}


export default withApollo(withRouter(Header));
