import gql from 'graphql-tag';

export const GET_NOTIFICATIONS = gql`
	{
		notifications @client{
			text
			success
			error
		}
	}
`;

export const GET_HEADER_DATA = gql`
	query AllCategories($lang:String){
		allCategories(lang:$lang){
			categoryID
			name
			lang
			niceName
			hasNewVideo
			videos{
				hash
				name
				lang
				activeForUser
				viewed
				videoID
			}
		}
		
		customerContext{
			membershipTo
			customerID
			regCode
			domainID
			membershipDuration
			name
			surname
			email
			city
			street
			zip
			tel
			domain{
				domainName
				protocol
			}
			completedCustomerPaymentsCount
			membership{
				unlockAllVideos
			}
		}

		allCustomerVideos(lang:$lang){
			hash
			name
			lang
			activeForUser
			viewed
			videoID
		}
		
	}
`;

export const SEND_QUESTION = gql`
	mutation SendQuestion($text:String){
		sendQuestion(text:$text)	
	}
`;

export const GET_DOMAIN_DATA_BY_DOMAIN = gql`
	query DomainDataByDomain($domain:String){
		domainDataByDomain(domain:$domain){
			lang
		}	
	}
`;