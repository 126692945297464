let graphqlServerUrl              = "https://server.astralnicestovani.cz/graphql";
let graphqlServerWsUrl            = "wss://server.astralnicestovani.cz/graphql";
let stripeKey                     = "pk_live_51KQUEHExB764KNHrmASVGimWIqAh4PX4ZDTafa4m5hlMOhQUrZJJ1ChZiTuaCk3kCib8qdYRMWtEFYSJBe68zL4W006HveQP3o";


if (process && process.env && process.env.NODE_ENV === "development") {
    graphqlServerUrl              = "http://localhost:8904/graphql";
    graphqlServerWsUrl            = "ws://localhost:8904/graphql";
    stripeKey                     = "pk_test_51KQUEHExB764KNHrJuNFzvDe1EiBqELUxZ4iB3sT6FlQ3ePhNjCBxXZCfb0Kfchp2I59Dm72zgu1KUITWYk3hYhW00PH9dzEaT";

}

export const GRAPHQL_SERVER_URL     = graphqlServerUrl;
export const GRAPHQL_SERVER_WS_URL  = graphqlServerWsUrl;
export const STRIPE_PKEY            = stripeKey;



